import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { fromEvent } from 'rxjs';

export const NetworkStore = signalStore(
  { providedIn: 'root' },
  withState({ isOnline: true }),
  withMethods((store) => ({
    online() {
      patchState(store, { isOnline: true });
    },
    offline() {
      patchState(store, { isOnline: false });
    },
  })),
  withHooks({
    onInit({ online, offline }) {
      fromEvent(window, 'online')
        .pipe(takeUntilDestroyed())
        .subscribe(() => online());
      fromEvent(window, 'offline')
        .pipe(takeUntilDestroyed())
        .subscribe(() => offline());
    },
  })
);
